<template>
  <div>
    <!--增加了一个ifHeader的props，默认不显示header -->
    <k-form-design @save="handleSave" toolbarsTop :toolbars="['save', 'preview', 'reset']" showToolbarsText showHead="false" ifHeader />
  </div>
</template>

<script>
export default {
  name: 'MainForm',
  props: {
    msg: String
  },
  methods: {
    handleSave(values) {
      alert('触发保存方法',values);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 10px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
